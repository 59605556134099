@import "abstracts/_abstracts";
.TabsIcon {
	$block: &;

	&-switcher {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

		#{$block}--subpage & {
			grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
		}
	}

	&-switch {
		display: flex;
		align-items: center;
		gap: 1rem;
		font-size: 1.25rem;
		line-height: 1.2;
		font-weight: 700;
		color: var(--colorText);
		transition: var(--animationBase);
		padding: 1.5rem;
		border-top-left-radius: 0.5rem;
		border-top-right-radius: 0.5rem;

		#{$block}--subpage & {
			padding: 0.75rem;
			font-size: 1rem;
			gap: 0.5rem;
			font-weight: 500;
		}

		&.is-active {
			background: var(--colorSecondaryBg);
		}

		&.is-responsive {
			margin-right: 0;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
			color: var(--colorBrand);

			#{$block}--subpage & {
				width: 1.25rem;
				height: 1.25rem;
			}
		}

		@include on-event {
			text-decoration: none;
		}

		+ #{$block}-tab {
			display: block;
			max-height: 0;
			overflow: hidden;

			&.is-active {
				max-height: 2000px;
				padding: var(--spaceMd) 0;

				@include media('<=sm') {
					padding-top: 0;
				}
			}
		}
	}

	&-tab {
		display: none;
		transition: var(--animationBase);
		background-color: var(--colorSecondaryBg);
		border-radius: 0.5rem;

		@include media('<=sm') {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		&:first-of-type {
			border-top-left-radius: 0;
		}

		&:last-of-type {
			border-top-right-radius: 0;
		}

		&.is-active {
			display: block;
		}
	}

	&-title {
		text-transform: uppercase;
		font-size: var(--textXl);
		display: none;
	}
}